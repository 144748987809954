import React from "react"
import HomeLayout from "../layout/homeLayout"
import SEO from "../components/seo"

function About() {
  return (
    <HomeLayout>
      <SEO title="Padding | Gangs of Gamer Free online HTML 5 games" />
      <div style={{ padding: "20px" }}>
        <h1>About Us</h1>
        <p>
          Gangs of Gamer is a online fun playground of HTML 5 games. We have over 60
          games. Our games works on android, ios, desktop devices. Our games are
          optimized to give you best experience on all global browsers. Our
          games run in browser so yuo will not have to download anything extra
          to play many games at once. We are dedicated to provide best
          entertainment and gaming experience to our user. We are ambitious to
          integrate more and more games to our platform.
        </p>
        <p>
          We have integrated some of the best HTML 5 games from the web from
          various developers. All the games are purchased and licensed. These
          games are either created by Gangs of Gamer or various developers or
          developement studios who sell/license their HTML5 games to Gangs of Gamer.
          These games run online within a browser enviourment so you will not
          have to download anything.
        </p>
        <p>
          You can contact us at
          <a href="mailto: sdsdeveloper@gmail.com">sdsdeveloper@gmail.com</a>
        </p>
      </div>
    </HomeLayout>
  )
}

export default About
